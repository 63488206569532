import React, { useContext, useEffect, useState } from "react";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import Loading from "./Loading";

function Stats() {
  const { userLoginState } = useContext(AuthState);
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState();
  const [showFormTotally, setShowFormTotally] = useState(false);
  const [showFormAlk, setShowFormAlk] = useState(false);
  const [showFormFood, setShowFormFood] = useState(false);
  const [showFormWp, setShowFormWp] = useState(false);
  const token = localStorage.getItem("login_token");

  useEffect(() => {
    if (userLoginState) {
      setLoading(true);
      UserService.getTasks(token)

        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then((data) => {
          let sortedByDate = data.results[0].sort(
            (p1, p2) => (p1.date_task < p2.date_task) ? 1 : (p1.date_task > p2.date_task) ? -1 : 0);
          setStatsData(sortedByDate);
        });
    }
  }, [token, userLoginState]);

  const showFormClickTotal = () => {
    setShowFormTotally(!showFormTotally);
  }

  const showFormClickAlk = () => {
    setShowFormAlk(!showFormAlk);
  }
  const showFormClickFood = () => {
    setShowFormFood(!showFormFood);
  }

  const showFormClickWp = () => {
    setShowFormWp(!showFormWp);
  }

  const totalFree = statsData.filter(entry => entry.alk_task === 0 && entry.food_cheat === 0 && entry.wp_task === 0);

  const alkFree = statsData.filter(entry => entry.alk_task === 0);
  const foodCheatFree = statsData.filter(entry => entry.food_cheat === 0);
  const wpFree = statsData.filter(entry => entry.wp_task === 0);

  console.log(alkFree);
  // console.log(loading);

  // from 2023-01-01
  const totCal = statsData.reduce((acc, obj) => {
    return acc + Number(obj.calories);
  }, 0);
  const avgCal = Math.round(totCal / statsData.length);

  const totWk = statsData.reduce((accumulator, obj) => {
    return accumulator + Number(obj.morning_wk) + Number(obj.evening_wk);
  }, 0);
  const avgWk = Math.round(totWk / statsData.length);

  // last 7 days

  const weekData = statsData.slice(0, 7);
  // console.log(weekData);

  const totCalWeek = weekData.reduce((acc, obj) => {
    return acc + Number(obj.calories);
  }, 0);
  const avgCalWeek = Math.round(totCalWeek / weekData.length);

  const totWkWeek = weekData.reduce((accumulator, obj) => {
    return accumulator + Number(obj.morning_wk) + Number(obj.evening_wk);
  }, 0);
  const avgWkWeek = Math.round(totWkWeek / weekData.length);

  const checkStatsExists =
  loading ? 
  <><div>Loading...</div>
  <Loading/></> : (
    statsData.length === 0 ? (
      <>
        <div>No statistics yet </div>
      </>
    ) : (
      <h1>Statistics</h1>
    ))

  return (
    <>
      <div className="stats-wrapper">
      <div className="stats-title">{checkStatsExists}</div>
      <div className="stats-wrapp free-totally">
          <div>
            <h1>Free totally:</h1>
            <div>{totalFree.length} out of {statsData.length}</div>
          </div>
          <button onClick={showFormClickTotal}>{showFormTotally ? "Hide" : "Show dates"}</button>
          {showFormTotally && (
        <div>
            {totalFree.map((free) =>(
              <div  key={free.id}>{free.date_task}</div>
            ))}
          </div>
          
        
      )}
          
          
        </div>
        <div className="stats-wrapp free-alk">
          <div>
            <h1>Alk free:</h1>
            
            <div>{alkFree.length} out of {statsData.length}</div>
          </div>
          <button onClick={showFormClickAlk}>{showFormAlk ? "Hide" : "Show dates"}</button>
        {showFormAlk && (
        <div>
            {alkFree.map((free) =>(
              <div key={free.id}>{free.date_task.slice(0, 10)}</div>
            ))}
          </div>
          
        
      )}
          
          
        </div>

        <div className="stats-wrapp free-alk">
          <div>
            <h1>WP free:</h1>
            
            <div>{wpFree.length} out of {statsData.length}</div>
          </div>
          <button onClick={showFormClickWp}>{showFormWp ? "Hide" : "Show dates"}</button>
        {showFormWp && (
        <div>
            {wpFree.map((free) =>(
              <div key={free.id}>{free.date_task.slice(0, 10)}</div>
            ))}
          </div>
          
        
      )}
          
          
        </div>

        <div className="stats-wrapp free-food-cheat">
          <div>
            <h1>Food cheat free:</h1>
            
            <div>{foodCheatFree.length} out of {statsData.length}</div>
          </div>
          <button onClick={showFormClickFood}>{showFormFood ? "Hide" : "Show dates"}</button>
        {showFormFood && (
        <div>
            {foodCheatFree.map((free) =>(
              <div key={free.id}>{free.date_task.slice(0, 10)}</div>
            ))}
          </div>
          
        
      )}
          
          
        </div>
        
        <div className="stats-wrapp stats-total">
          <div>
            <h1>Since 2023-01-01:</h1>
          </div>
          <div>Total:</div> <span>{totCal} kCal</span>
          <div>Avg daily:</div> <span>{avgCal} kCal</span>
          <div>Total workout:</div> <span>{totWk} minutes</span>
          <div>Avg daily:</div> <span>{avgWk} Minutes</span>
        </div>
        <div className="stats-wrapp stats-week">
          <div>
            <h1>Last 7 days:</h1>
          </div>
          <div>Total:</div> <span>{totCalWeek} kCal</span>
          <div>Avg daily:</div> <span>{avgCalWeek} kCal</span>
          <div>Total workout:</div> <span>{totWkWeek} minutes</span>
          <div>Avg daily:</div> <span>{avgWkWeek} Minutes</span>
        </div>
      </div>
    </>
  );
}

export default Stats;
