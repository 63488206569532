import React from "react";


function Loading() {
  return (
    <div id="kitt">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
          <div className="four"></div>
          <div className="five"></div>
          <div className="six"></div>
          <div className="seven"></div>
          <div className="eight"></div>
          <div className="nine"></div>
          <div className="ten"></div>
        </div>
  );
}

export default Loading;
