import React from "react";


function Footer() {
  return (
    <div className="footer-wrapper">
      <p>Copyright @ Paulius</p>
      <div id="footer-scroll">
        <a href="#top">
          Up
        </a>
      </div>
    </div>
  );
}

export default Footer;
